/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import useLearningPart from '../../hooks/useLearningPart';

const LearningPart = () => {
    const [learningPart, setLearningPart] = useLearningPart();
    return (
        <div className='kib_box_shadow w-12/12 max-w-screen-lg mx-5  md:mx-10 mt-9 rounded-lg py-10 px-5 md:px-20 text-[#6d7381]'>

            <div>
                <h1 className='font-bold md:text-2xl pb-2' > Learn with Us and Go Ahead </h1>
                <h2 className='md:font-semibold mb-4 text-[#8a92a5]'> Enhance your coding skills with practical lessons and expert guidance to advance your career. </h2>
                <h3 className='font-normal text-xs mb-6 text-[#8a92a5]'>
                    Unlock your potential with our expert resources and guidance. Whether you're just starting or looking to advance, our platform is designed to help you learn, grow, and achieve your development goals. Join us today and take the next step toward success!
                </h3>
            </div>

            <div className=' grid grid-cols-1 lg:grid-cols-2 gap-6'>

                {
                    learningPart.map(learnPart => (
                        <div className="bg-slate-200 p-3 text-center shadow-2xl shadow-slate-400" key={learnPart.learningTitle}>
                            <h1 className="font-bold md:text-xl pb-4">{learnPart.learningTitle}</h1>
                            <img
                                className="w-full mx-auto rounded-lg"
                                src={learnPart.learningImg}
                                alt={learnPart.learningTitle}
                            />


                            <p className="text-sm my-4">
                                <b>Topics: </b> {learnPart.learningTopics} <br/>
                                <b>Description: </b>{learnPart.learningShortText}
                            </p>

                            <div className=" text-sm">
                                {
                                    learnPart.learningButtons.map((button, index) => (
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={button.learningButtonUrl}
                                            key={index}
                                            className="kib_inside_effect inline-block m-1 p-2"
                                        >
                                            {button.learningButtonText}
                                        </a>
                                    ))
                                }
                            </div>

                        </div>
                    ))
                }

            </div>

        </div>
    );
};

export default LearningPart;