import React from 'react';
import { FaGithubSquare, FaLinkedin, FaWhatsappSquare,FaFacebookSquare } from "react-icons/fa";
import { Link } from 'react-router-dom';

const About = () => {
    const currentDomain = window.location.hostname;
    return (
        <div className='kib_box_shadow w-12/12  md:w-8/12 max-w-screen-lg mx-5  md:mx-10 mt-9 rounded-lg py-10 px-5 md:px-20 text-[#6d7381]'>
            <h2 className=' text-center text-3xl mb-10 font-bold  underline underline-offset-8 decoration-0 decoration-dotted text-[#8a92a5] hover:decoration-2 '> 
                {currentDomain === 'netkib.com' ? (
                    <> About Netkib </>
                ) : currentDomain === 'kibria.net' ? (
                    <> I'm Golam kibria, a WordPress and Frontend developer. </>
                ) : (
                    <> About </>
                )}
            </h2>

            <div className='text-left flex flex-col gap-5 '>
                <p>
                    With over 6 years of experience in WordPress development, this service delivers comprehensive solutions for businesses and individuals seeking high-quality, customized websites. Expertise spans a wide range of services, including <b> WordPress Maintenance, WordPress WooCommerce setup and management, WordPress Plugin Development, WordPress Theme Development, and WordPress Theme Customization.</b>
                </p>
                <p>
                    Each project focuses on creating optimized, responsive sites designed to enhance user experience and SEO performance. <b>WordPress Plugin Customization and WordPress Speed Optimization </b> ensure that each website not only meets functional needs but also performs efficiently across devices. Skilled in resolving <b> Website Errors</b>, services are designed to maintain the smooth operation and security of websites.
                </p>
                <p>
                    Beyond WordPress, this service also offers <b> React.js & Next.js Application Development </b>, expanding capabilities to support more advanced and custom-built applications. Social media setup and management services provide a complete online presence, including <b> LinkedIn Profile Setup with Services Page and Facebook and Instagram Setup and Management.</b>
                </p>
                <p>
                    With a commitment to security, scalability, and adaptability, this service delivers sustainable, impactful solutions that evolve with changing needs. By keeping up with industry trends and utilizing SEO-friendly practices, each project aims to provide clients with seamless and effective web experiences across diverse industries.
                </p>

                <p>
                    <b> Skills include: </b>
                    WordPress (theme & Plugin development), Next.js, Reactjs, Tailwind css, Bootstrap css, Photoshop, Figma, Javascript, Typescript, PHP, Redux, Node.js, Express, MongoDB, Mongoosejs, MySQL, Prisma, PostgreSQL,
                </p>

                <div>
                    <Link
                        className='p-2 px-3 rounded kib_inside_effect mr-5' to='/MyTools' >
                        My Tools
                    </Link>
                    <Link
                        className='p-2 px-3 rounded kib_inside_effect' to='/Blogs' >
                        Blog
                    </Link>

                </div>


                <div className=" flex  justify-center pt-8 pb-4 gap-5 ">
                    <a target='_blank' rel="noreferrer" aria-label="My Linkedin Profile Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://github.com/kibria-khandaker" >
                        <FaGithubSquare />
                        <span className="tooltiptext2 text-sm "> Github </span>
                    </a>
                    <a target='_blank' rel="noreferrer" aria-label="My Twitter Profile Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://www.linkedin.com/in/kibria-khandaker/" >
                        <FaLinkedin />
                        <span className="tooltiptext2 text-sm "> Linkedin </span>
                    </a>
                    <a target='_blank' rel="noreferrer" aria-label="My WhatsApp Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://wa.me/+8801913595268" >
                        <FaWhatsappSquare />
                        <span className="tooltiptext2 text-sm "> WhatsApp </span>
                    </a>
                    <a target='_blank' rel="noreferrer" aria-label="My Facebook Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://m.me/amikibria" >
                        <FaFacebookSquare   />
                        <span className="tooltiptext2 text-sm "> Facebook </span>
                    </a>
                </div>

                <Link
                    className='text-xs italic font-thin underline'
                    to='/UpdateHistory'
                >
                    Check Content Update History
                </Link>

            </div>
        </div>
    );
};

export default About;