import { useEffect, useState } from 'react';

const useResumeDownload = () => {
    const [resumeDownloads, setResumeDownloads] = useState([]);

    useEffect(() => {
        fetch('https://raw.githubusercontent.com/kibria-khandaker/API-Data/main/myResumeDownload.json')
            .then(res => res.json())
            .then(data => setResumeDownloads(data))
    }, [])
    
    return [resumeDownloads, setResumeDownloads];
};

export default useResumeDownload;


