import React from 'react';
import useResumeDownload from '../../hooks/useResumeDownload';

const ResumeDownloadBtn = () => {
    const [resumeDownloads, setResumeDownloads] = useResumeDownload();

    return (
        <div className='w-full overflow-hidden flex flex-col p-5 '>
            <h1 className='text-[#7a8190] font-bold text-xs'>Download resume</h1>
            {
                resumeDownloads.slice(-1).map(resumeDownload => (
                    <a target='_blank' rel="noreferrer"
                        aria-label={resumeDownload.resumeDownloadBtnSubTitle}
                        className='kib_inside_effect text-[#7a8190] rounded inline-block mt-4 p-2 text-xs '
                        href={resumeDownload.resumeDownloadBtnUrl} key={resumeDownload._id}
                    >
                        {resumeDownload.resumeDownloadBtnTitle}
                        {/* Download Resume */}
                    </a>
                ))
            }
        </div>
    );
};

export default ResumeDownloadBtn;