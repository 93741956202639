import React from 'react';
import { FaGithubSquare, FaLinkedin, FaWhatsappSquare, FaFacebookSquare } from "react-icons/fa";
import useOurTeamMembers from '../../hooks/useOurTeamMembers';

const OurTeam = () => {
    const [ourTeamMembers, setOurTeamMembers] = useOurTeamMembers();
    return (
        <div className='kib_box_shadow w-12/12  md:w-8/12 max-w-screen-lg mx-5  md:mx-10 mt-9 rounded-lg py-10 px-5 md:px-20 text-[#6d7381]'>

            <div className='text-left flex flex-col gap-5 '>

                <div className=' text-center'>
                    <h1 className='font-bold md:text-2xl mb-3 ' > Here All Experts Details </h1>
                    <h2 className='md:font-semibold mb-6 text-[#8a92a5]'> Expert Digital Solutions: Apps, Websites, SEO, Social Marketing, and Content to Drive Your Growth </h2>
                </div>

                <div className='grid grid-cols-2 gap-5 text-center' >
                    {
                        ourTeamMembers.map(ourTeamMember => (
                            <p>
                                <img className=' w-40 mx-auto rounded-lg' src={ourTeamMember.teamMemberImg} alt={ourTeamMember.teamMemberPosition} />
                                <b> {ourTeamMember.teamMemberName} </b>
                                <p className='text-xs'> {ourTeamMember.teamMemberPosition} </p>
                                <i className='text-xs font-thin'> {ourTeamMember.teamMemberAddress} </i>
                            </p>

                        ))
                    }
                </div>

                <p className=' text-center'>
                    Our team offers a comprehensive range of services to meet diverse client needs. We specialize in developing innovative Android applications and crafting dynamic, user-friendly websites and applications. Our expertise extends to enhancing online presence through advanced SEO strategies and impactful social media marketing. Additionally, we provide high-quality content creation and detailed analysis to ensure effective communication and data-driven insights. Together, we deliver tailored solutions designed to drive growth and success for our clients.
                </p>

                <div className=" flex  justify-center pt-8 pb-4 gap-5 ">
                    <a target='_blank' rel="noreferrer" aria-label="My Linkedin Profile Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://github.com/kibria-khandaker" >
                        <FaGithubSquare />
                        <span className="tooltiptext2 text-sm "> Github </span>
                    </a>
                    <a target='_blank' rel="noreferrer" aria-label="My Twitter Profile Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://www.linkedin.com/in/kibria-khandaker/" >
                        <FaLinkedin />
                        <span className="tooltiptext2 text-sm "> Linkedin </span>
                    </a>
                    <a target='_blank' rel="noreferrer" aria-label="My WhatsApp Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://wa.me/+8801913595268" >
                        <FaWhatsappSquare />
                        <span className="tooltiptext2 text-sm "> WhatsApp </span>
                    </a>
                    <a target='_blank' rel="noreferrer" aria-label="My Facebook Link" className='kib_inside_effect tooltip2  text-[#8a92a5] rounded-full py-3 px-3' href="https://m.me/amikibria" >
                        <FaFacebookSquare />
                        <span className="tooltiptext2 text-sm "> Facebook </span>
                    </a>
                </div>

            </div>
        </div>
    );
};

export default OurTeam;