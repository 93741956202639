import { useEffect, useState } from 'react';

const useLearningPart = () => {
    const [learningParts, setLearningParts] = useState([]);

    useEffect(()=>{
        fetch('https://raw.githubusercontent.com/kibria-khandaker/API-Data/main/learningPartData.json')
        .then(res=>res.json())
        .then(data=>setLearningParts(data))
    },[])

    return [learningParts, setLearningParts];
}

export default useLearningPart; 