import { useEffect, useState } from 'react';

const useOurTeamMembers = () => {
    const [ourTeamMembers, setOurTeamMembers] = useState([]);

    useEffect(()=>{
        fetch('https://raw.githubusercontent.com/kibria-khandaker/API-Data/main/ourTeamMembers.json')
        .then(res=>res.json())
        .then(data=>setOurTeamMembers(data))
    },[])

    return [ourTeamMembers, setOurTeamMembers];
}

export default useOurTeamMembers;